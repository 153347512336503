import React, { useCallback, useMemo, createContext, useContext, useState, useEffect } from 'react';
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import omitBy from 'lodash/omitBy';

import { setCookie, getCookie, getVisitorLocationCookie, setVisitorLocationCookie } from 'utils/cookie';
import { useVisitorLocation } from 'utils/hooks/useLocationFilter';
import { sentryCaptureException } from 'utils/globals/sentry';

import { OB_VISITOR_ID, OB_VISITOR_LOCATION } from 'constants/cookieConstants';

import { i18n, defaultLanguage } from 'src/i18n';

const useAndSaveVisitorLocation = (visitorData, hasFingerprintFailed = false, serverCookies = {}) => {
  const visitorId = visitorData?.visitorId;
  const savedVisitorID = getCookie(OB_VISITOR_ID) || serverCookies[OB_VISITOR_ID];
  const { city: savedCity, country: savedCountry, region: savedRegion } = getVisitorLocationCookie(
    serverCookies[OB_VISITOR_LOCATION],
  );

  const { shouldFetchLocationDetails, isReady } = useMemo(() => {
    const isMissingSavedLocation = !savedCity?.id || !savedCountry?.id || !savedRegion?.id;

    if (!visitorId) {
      return {
        shouldFetchLocationDetails: false,
        isReady: !isMissingSavedLocation || hasFingerprintFailed,
      };
    }

    const isVisitorIDChanged = !savedVisitorID || visitorId !== savedVisitorID;

    return {
      shouldFetchLocationDetails: isVisitorIDChanged || isMissingSavedLocation,
      isReady: !isMissingSavedLocation,
    };
  }, [savedCity?.id, savedCountry?.id, savedRegion?.id, savedVisitorID, visitorId, hasFingerprintFailed]);

  const ipLocation = useMemo(() => {
    const { city: ipCity, country: ipCountry, continent: ipContinent, subdivisions: _subdivisions, ...rest } =
      visitorData?.ipLocation || {};

    return {
      ...rest,
      city: ipCity?.name,
      country: ipCountry?.name,
      continent: ipContinent?.name,
    };
  }, [visitorData?.ipLocation]);

  const { city, country, region, isFetched } = useVisitorLocation(ipLocation, {
    enabled: shouldFetchLocationDetails,
  });

  useEffect(() => {
    if (isFetched) {
      setVisitorLocationCookie({ city, country, region });
    }
  }, [city, country, region, isFetched]);

  if (shouldFetchLocationDetails) {
    return { ipLocation, city, country, region, isReady: isFetched, fromCookies: false };
  }

  return {
    ipLocation,
    city: savedCity,
    country: savedCountry,
    region: savedRegion,
    isReady,
    fromCookies: true,
  };
};

const AppContext = createContext({});

export const AppContextProvider = ({ children, sessionUser, locale, isTrustedBot, serverCookies }) => {
  const { data: visitorData, isLoading, error } = useVisitorData({ extendedResult: true });
  const hasFingerprintFailed = useMemo(() => !isLoading && (!!error || !visitorData?.visitorId), [
    error,
    isLoading,
    visitorData?.visitorId,
  ]);

  const savedVisitorID = getCookie(OB_VISITOR_ID) || serverCookies[OB_VISITOR_ID];

  const location = useAndSaveVisitorLocation(visitorData, hasFingerprintFailed, serverCookies);

  const [scrollBarWidth, setScrollBarWidth] = useState(0);
  const [storeUserId, setLoggedInUserId] = useState(sessionUser?.id);
  const [paywallType, setPaywallType] = useState('');
  const language = useMemo(() => {
    const serverLanguage = locale || defaultLanguage;

    if (typeof window === 'undefined') {
      return serverLanguage?.toLowerCase();
    }

    return (i18n?.language || serverLanguage)?.toLowerCase();
  }, [locale]);

  useEffect(() => {
    if (hasFingerprintFailed) {
      sentryCaptureException(error, { extra: { savedVisitorID } });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasFingerprintFailed]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      setScrollBarWidth(window.innerWidth - document.body.clientWidth);
    }
  }, []);

  useEffect(() => {
    if (visitorData?.visitorId && visitorData.visitorId !== savedVisitorID) {
      setCookie(OB_VISITOR_ID, visitorData.visitorId);
    }
  }, [visitorData?.visitorId, savedVisitorID]);

  const visitorId = useMemo(() => visitorData?.visitorId || savedVisitorID, [visitorData?.visitorId, savedVisitorID]);

  const appContextValue = useMemo(
    () => ({
      isTrustedBot,
      userId: storeUserId,
      isLoggedIn: !!storeUserId,
      setLoggedInUserId,
      language,
      paywallType,
      setPaywallType,
      scrollBarWidth,
      visitorId,
      requestId: visitorData?.requestId,
      location,
      hasFingerprintFailed,
    }),
    [
      isTrustedBot,
      language,
      location,
      paywallType,
      scrollBarWidth,
      storeUserId,
      visitorData?.requestId,
      visitorId,
      hasFingerprintFailed,
    ],
  );

  const debugAppContext = useCallback(() => {
    const logger = console;
    const data = omitBy(appContextValue, value => typeof value === 'function');

    logger.log(data);
  }, [appContextValue]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      window.debugAppContext = debugAppContext;
    }
  }, [debugAppContext]);

  return <AppContext.Provider value={appContextValue}>{children}</AppContext.Provider>;
};

const useAppContext = () => useContext(AppContext);

export default useAppContext;
