import cookie from 'js-cookie';
import { OB_UTM, OB_VISITOR_LOCATION } from 'constants/cookieConstants';

export const getCookie = cname => {
  const cookieValue = cookie.get(cname);

  if (['undefined', 'null'].includes(cookieValue)) {
    return '';
  }

  return cookieValue;
};

export const removeCookie = (key, options) => {
  if (typeof window !== 'undefined') {
    cookie.remove(key, {
      expires: 1,
      ...(options || {}),
    });
  }
};

export const setCookie = (key, value, expires) => {
  if (typeof window !== 'undefined') {
    cookie.set(key, value, {
      expires,
      path: '/',
    });
  }
};

export const setVisitorLocationCookie = ({ city, country, region }) => {
  const getLocationString = location => `${location?.name || ''}-${location?.slug || ''}-${location?.id || ''}`;
  const locationParts = [city, country, region].map(getLocationString).join('::');

  setCookie(OB_VISITOR_LOCATION, locationParts, 1);
};

export const getVisitorLocationCookie = serverCookie => {
  const cookieValue = getCookie(OB_VISITOR_LOCATION) || serverCookie;

  if (cookieValue) {
    const getLocationObject = locationString => {
      const [name, slug, id] = locationString.split('-');

      return {
        name,
        slug,
        id: id ? parseInt(id, 10) : null,
      };
    };

    const [city, country, region] = cookieValue.split('::').map(getLocationObject);

    return {
      city,
      country,
      region,
    };
  }

  return {};
};

export const processServerCookies = (cookieString = '') => {
  const cookies = cookieString.split(';');

  const parsedCookies = cookies?.reduce((acc, serverCookie) => {
    const [cookieName, cookieValue] = serverCookie.trim().split('=');
    acc[cookieName] = cookieValue;

    return acc;
  }, {});

  return {
    getServerCookie: cookieName => parsedCookies[cookieName],
  };
};

export const getCampaignAttributionDetails = cookieValue => {
  const campaignIdentifier = cookieValue || getCookie(OB_UTM);

  if (campaignIdentifier) {
    // NOTE: Changing this needs to be reflected in the adCampaignAttributionMiddleware & GOOGLE_ANALYTICS_COMMON_DATA_KEYS constants
    let [utmSource, utmMedium, utmCampaign, utmTerm, utmContent] = campaignIdentifier.split('::');

    if (!utmCampaign) {
      // TODO: Remove after 10th August 2024
      [utmSource, utmMedium, utmCampaign, utmTerm, utmContent] = campaignIdentifier.split('_');
    }

    return {
      utmSource,
      utmMedium,
      utmCampaign,
      utmTerm,
      utmContent,
    };
  }

  return {};
};
